import { Box, Grid, styled } from '@mui/material';
import { DropDown, MediaField } from 'components/Forms';
import { FieldWrapper } from 'designSystem';
import { FastField as Field, useFormikContext } from 'formik';
import React, { FC } from 'react';
import TextFieldLimited from 'components/Forms/TextFieldLimited';
import PartnerAutocompleteWrapper from 'components/ChainMapping/Forms/PartnerAutocomplete';
import LocationSelectorBlock from './LocationSelectorBlock';
import { SiteType } from 'types/sites.types';
import { FormValues } from './AddEditSiteDialog';
import FarmForm from './FarmForm';
import { SiteLabelKeys } from 'utils/site.utils';
import HTMLEditorField from 'designSystem/Formik/HTMLEditorField/HTMLEditorField';
import { TextField } from 'formik-mui';

interface ISiteFormProps {
  hidePartnerSelector?: boolean;
  disableSiteSelection?: boolean;
}

const StyledCard = styled(Grid)(({ theme }) => ({
  position: 'relative',
  background: theme.custom.themeColors.white,
  boxShadow: theme.custom.shadows[4],
  borderRadius: 6,
  minHeight: 150,
  width: '100%',
  padding: theme.spacing(2),
  maxHeight: 'calc(100vh - 300px)',
  overflowY: 'auto',
}));

export const StyledDropDown = styled(DropDown)(({ theme }) => ({
  '& .MuiSelect-select': {
    height: '14px !important',
    minHeight: '14px !important',
    fontSize: 12,
    padding: '8px 12px !important',
  },
}));

const StyledTextField = styled(TextFieldLimited)(() => ({
  '&.MuiTextField-root': {
    '& .MuiFormLabel-root': {
      fontSize: 11,
      fontWeight: 300,
      '&[data-shrink="false"]': {
        transform: 'translate(14px, 10px) scale(1)',
      },
    },
    '& .MuiInputBase-input': {
      height: 16,
      padding: '9px 12px',
    },
  },
}));

const ArticleNumberInput = styled(TextField)(() => ({
  '&.MuiTextField-root': {
    '& .MuiFormLabel-root': {
      fontSize: 11,
      fontWeight: 300,
      '&[data-shrink="false"]': {
        transform: 'translate(14px, 10px) scale(1)',
      },
    },
    '& .MuiInputBase-input': {
      height: 16,
      padding: '9px 12px',
    },
  },
}));

const SiteForm: FC<ISiteFormProps> = ({ hidePartnerSelector, disableSiteSelection }) => {
  const { values, setFieldValue, errors } = useFormikContext<FormValues>();
  const siteTypeOptions = Object.entries(SiteType)
    .filter(([key]) => key !== SiteType.CLUSTER)
    .map(([key, value]) => ({
      key,
      value: SiteLabelKeys[value],
    }));

  return (
    <StyledCard container>
      <Grid item xs={3}>
        <FieldWrapper>
          <Field
            component={MediaField}
            name="image"
            data-cy="field-site-image"
            legacyImage={false}
            styles={{
              height: 130,
              maxWidth: '90%',
            }}
          />
        </FieldWrapper>
      </Grid>
      <Grid item xs={9} spacing={2}>
        <Grid item xs={12}>
          <Box display="flex">
            <FieldWrapper label="Site title" width="calc(100% - 126px)" mr={2}>
              <Field
                name="title"
                fullWidth
                placeholder="E.g. Farm name"
                component={StyledTextField}
                variant="outlined"
                charLimit={50}
                inputProps={{
                  'data-cy': 'field-site-title',
                }}
              />
            </FieldWrapper>
            <FieldWrapper label="Internal id" width={110}>
              <Field
                component={ArticleNumberInput}
                placeholder="Enter id"
                fullWidth
                name="externalId"
                variant="outlined"
                lang={null}
                data-cy="internal-id-input"
              />
            </FieldWrapper>
          </Box>
        </Grid>
        <Grid container marginTop={0} spacing={2}>
          <Grid item xs={hidePartnerSelector ? 12 : 6}>
            <FieldWrapper label="Site type" required>
              <Field
                component={StyledDropDown}
                name="siteType"
                variant="outlined"
                disabled={disableSiteSelection}
                fullWidth
                placeholder="Select site type (e.g. Farm)"
                currentValue={values.siteType}
                options={siteTypeOptions}
                errorMsg={errors.siteType}
                keyField="key"
                onChange={(item: SiteType) => setFieldValue('siteType', item)}
                renderItems={(item: { key: keyof typeof SiteType; value: SiteType }) => (
                  <Box>
                    <span>{item.value}</span>
                  </Box>
                )}
              />
            </FieldWrapper>
          </Grid>
          {!hidePartnerSelector && (
            <Grid item xs={6}>
              <PartnerAutocompleteWrapper
                name="partner"
                helperText="Select the owner of this site."
                labelVariant="medium"
                size="small"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container marginTop={0} spacing={2}>
        <Grid item xs={12}>
          <LocationSelectorBlock />
        </Grid>
        <Grid item xs={12}>
          <FieldWrapper label="Description">
            <Field
              component={HTMLEditorField}
              name="description"
              minHeight={80}
              charLimit={650}
              placeholder="Describe what is happening at this site..."
              data-cy="site-description-input"
            />
          </FieldWrapper>
        </Grid>
      </Grid>

      {values.siteType === 'FARM' && (
        <Box mt={2} width="100%">
          <FarmForm />
        </Box>
      )}
    </StyledCard>
  );
};

export default SiteForm;
