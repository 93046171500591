import React, { FC } from 'react';
import { Box, styled, Grid } from '@mui/material';
import RawMaterialSelection from 'components/ComponentsLibrary/Forms/RawMaterialSelection';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { FieldWrapper, ThemeTypography } from 'designSystem';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import ExtendedFarmForm from './ExtendedFarmForm';

const Container = styled(Box)(({ theme }) => ({
  background: theme.custom.themeColors.grayScale[10],
  borderRadius: 6,
  padding: theme.spacing(2),
}));

const RequiredContainer = styled(Box)(({ theme }) => ({
  background: '#fff',
  borderRadius: 6,
  padding: theme.spacing(2),
}));

export const SubTitle = styled(ThemeTypography)(({ theme }) => ({
  textTransform: 'uppercase',
  marginBottom: theme.spacing(1.5),
}));

export const StyledTextField = styled(TextField)(() => ({
  '&.MuiTextField-root': {
    '& .MuiInputBase-input': {
      fontSize: 12,
    },
  },
}));

const FarmForm: FC = () => {
  return (
    <Container>
      <Box display="flex" alignItems="center" mb={2}>
        <Icon name="farm" size="large" color="gray-60" />
        <Box ml={0.5} />
        <ThemeTypography variant="BODY_MEDIUM_BOLD" color="GRAY_60">
          Farm data
        </ThemeTypography>
        <Box ml={1} />
        <Icon name="info" size="large" />
        <Box ml={0.5} />
        <ThemeTypography variant="BODY_MEDIUM">
          Farms can also be imported in bulk in the Origin data import for a specific partner.
        </ThemeTypography>
      </Box>
      <RequiredContainer>
        <SubTitle variant="BODY_MEDIUM">Required farm data</SubTitle>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FieldWrapper width="100%" label="Size" required>
              <Field
                name="farmData.size"
                fullWidth
                placeholder="Enter farm size (Hectares)"
                component={StyledTextField}
                type="number"
                size="small"
                variant="outlined"
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={6}>
            <RawMaterialSelection
              isRequired
              label="Focus material"
              multiple={false}
              name="farmData.rawMaterialId"
              size="small"
              labelVariant="medium"
              helperText="Select the focus material of the farm"
            />
          </Grid>
        </Grid>
      </RequiredContainer>
      <Box mt={2} />
      <ExtendedFarmForm />
    </Container>
  );
};

export default FarmForm;
