import { useMutation } from '@apollo/client';
import { useDialog, useLogEvent, useMessages } from 'components/hooks';
import { CREATE_COMPONENT, DELETE_COMPONENT, UPDATE_COMPONENT } from 'graphql/mutations/components';
import { IUpdateComponentInput } from 'graphql/mutations/types/component-mutation.types';
import { IComponentItem } from 'types/component.types';
import { AvailableLanguagesType } from 'types/enums';

const useComponentItemActions = () => {
  const { openDialog, closeDialog } = useDialog();
  const { logEvent } = useLogEvent();
  const { setErrorMessage, setSuccessMessage } = useMessages();

  const handleCreateComponent = () => {
    openDialog({
      type: 'CREATE_EDIT_COMPONENT',
      props: {
        title: 'Create component',
      },
    });
  };

  const handleEditComponent = (
    component: Omit<IComponentItem, 'inUse' | 'lastEditedTimestamp'>
  ) => {
    openDialog({
      type: 'CREATE_EDIT_COMPONENT',
      props: {
        title: 'Edit component',
        componentItem: component,
      },
    });
  };

  const handleDeleteComponent = (componentId: string) => {
    openDialog({
      type: 'ALERT',
      props: {
        title: 'Delete component',
        displayCloseButton: true,
        text: 'Are you sure you want to delete this component? All data will be lost and you will not be able to recover this item.',
        submitText: 'Delete',
        onSubmit: () => {
          deleteComponent({ variables: { id: componentId } });
        },
        onCancel: () => closeDialog('ALERT'),
      },
    });
  };

  const [createComponent] = useMutation<{ createComponent: { component: IComponentItem } }>(
    CREATE_COMPONENT,
    {
      onCompleted: ({
        createComponent: {
          component: { id },
        },
      }) => {
        logEvent('CREATE_NEW_COMPONENT', {
          component_id: id,
        });
        setSuccessMessage('Success! Your component was created.');
      },
      onError: () => {
        // Loading of the dialog can not be deactivated here
        closeDialog();
        setErrorMessage('Something went wrong creating your component. Please try again!');
      },
    }
  );

  const [updateComponent] = useMutation<
    { updateComponent: IComponentItem },
    {
      id: string;
      input: IUpdateComponentInput;
      lang?: AvailableLanguagesType;
    }
  >(UPDATE_COMPONENT, {
    onCompleted: ({ updateComponent: { id } }) => {
      closeDialog();
      logEvent('UPDATE_COMPONENT', {
        component_id: id,
      });
      setSuccessMessage('Success! Your component was updated.');
    },
    onError: () => {
      // Loading of the dialog can not be deactivated here
      closeDialog();
      setErrorMessage('Something went wrong creating your component. Please try again!');
    },
  });

  const [deleteComponent] = useMutation(DELETE_COMPONENT, {
    onCompleted: ({ deleteComponent: { id } }) => {
      logEvent('DELETE_COMPONENT', {
        component_id: id,
      });
      setSuccessMessage('Success! Your component was deleted.');
      closeDialog();
    },
    onError: () => {
      setErrorMessage('Something went wrong deleting your component');
    },
    update: (cache, data) => {
      cache.modify({
        fields: {
          components: (existingComponentRefs, { readField }) => ({
            edges: existingComponentRefs.edges.filter(
              ({ node }: { node: { __ref: string } }) =>
                readField('id', node) !== data?.data?.deleteComponent?.id
            ),
          }),
        },
      });
    },
  });

  return {
    handleCreateComponent,
    handleEditComponent,
    handleDeleteComponent,
    createComponent,
    deleteComponent,
    updateComponent,
  };
};

export default useComponentItemActions;
