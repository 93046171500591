import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Edit } from '@styled-icons/boxicons-solid';
import { PartnerPlaceholderImage } from 'assets/img';
import defaultComponentImage from 'assets/img/components/component-default.png';
import ComponentChainTable from 'components/ComponentsLibrary/ComponentChainTable/ComponentChainTable';
import ProductSupplyChainTable from 'components/ComponentsLibrary/ProductSupplyChainTable/ProductSupplyChainTable';
import { ITableRowAction } from 'components/ComponentsLibrary/TableRow';
import { COMPONENT_CHAIN_COLUMN_IDS } from 'components/ComponentsLibrary/constants/headerColumns';
import useComponentChainActions from 'components/ComponentsLibrary/hooks/useComponentChainActions';
import { Loader } from 'components/Forms';
import { ErrorState, FlexBox, PageContainer, PageTitle } from 'components/Structure';
import OverviewHeader from 'components/Structure/OverviewHeader/OverviewHeader';
import { CardIllustration, EmptyStateCard, Tabs, ThemeButton } from 'designSystem';
import ConditionalRender from 'designSystem/Layout/CondionalRender/ConditionalRender';
import Icon from 'designSystem/Primitives/Icon/Icon';
import { ITab } from 'designSystem/Primitives/Tabs/Tabs';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AvailableSizes, BackgroundTypes, IllustrationTypes, ItemTypes } from 'types/enums';
import { useQuery } from '@apollo/client';
import { IComponentItem } from 'types/component.types';
import { GET_COMPONENT } from 'graphql/queries';
import { useDialog } from 'components/hooks';
import Image from 'designSystem/DataDisplay/Image/Image';

interface IComponentItemOverviewProps {
  renderInsidePartnerLibrary?: boolean;
}

enum IComponentOverviewTabs {
  COMPONENT_CHAINS = 'COMPONENT_CHAINS',
  PRODUCT_SUPPLY_CHAINS = 'PRODUCT_SUPPLY_CHAINS',
}

const COMPONENT_OVERVIEW_TABS: ITab[] = [
  {
    key: IComponentOverviewTabs.COMPONENT_CHAINS,
    label: 'Component chains',
    startIconName: 'component-chain',
  },
  {
    key: IComponentOverviewTabs.PRODUCT_SUPPLY_CHAINS,
    label: 'Product supply chains',
    startIconName: 'supply-chain',
  },
];

const StyledSubTitle = styled(ThemeTypography)(({ theme }) => ({
  textTransform: 'uppercase',
}));

const ComponentItemOverview: FC<IComponentItemOverviewProps> = ({ renderInsidePartnerLibrary }) => {
  const { createComponentChain } = useComponentChainActions();
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<IComponentOverviewTabs>(
    IComponentOverviewTabs.COMPONENT_CHAINS
  );
  const { id: paramId, componentId } = useParams();

  const { data, loading, error } = useQuery<{ component: IComponentItem }>(GET_COMPONENT, {
    variables: {
      id: renderInsidePartnerLibrary ? componentId : paramId,
    },
  });
  const component = data?.component;

  const isPartOfChain = !!(component?.componentChains?.length || component?.supplyChains?.length);

  const handleChangeTab = (tab: string) => {
    setCurrentTab(tab as IComponentOverviewTabs);
  };

  const handleCreateComponentChain = () => {
    createComponentChain();
  };

  const handleEditComponent = (
    component: Omit<IComponentItem, 'inUse' | 'lastEditedTimestamp'>
  ) => {
    openDialog({
      type: 'CREATE_EDIT_COMPONENT',
      props: {
        title: 'Edit component',
        componentItem: component,
        hidePartner: renderInsidePartnerLibrary,
      },
    });
  };

  const handleRowClick = (id: string) => {
    navigate(`/component-chains/${id}/editor`);
  };

  const componentTableActions: ITableRowAction[] = [
    {
      renderIcon: () => <Edit size="17" />,
      tooltip: 'Edit component chain',
      onClick: (id: string) => navigate(`/component-chains/${id}/editor`),
    },
  ];

  const supplyChainTableActions: ITableRowAction[] = [
    {
      renderIcon: () => <Edit size="17" />,
      tooltip: 'Edit supply chain',
      onClick: (chainId: string) => {
        const productId = component?.supplyChains?.find(chain => chain.id === chainId)?.productId;
        navigate(`/supply-chains/${productId}/${chainId}/editor`);
      },
    },
  ];

  if (loading) return <Loader />;
  if (error || !component) return <ErrorState />;

  return (
    <PageContainer>
      <PageTitle
        title="Component overview"
        goBackLabel={renderInsidePartnerLibrary ? 'Back to partner' : 'All components'}
        goBackUrl={renderInsidePartnerLibrary ? `/partners/${paramId}/components` : '/components'}
        titleExtension={component?.title}
      />
      <Box mt={2.5} />
      <Grid item xs={12}>
        <OverviewHeader imageUrl={defaultComponentImage} image={component?.image || undefined}>
          <Box display="flex" justifyContent="space-between" flex={1} height="100%">
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              justifyContent="center"
              alignItems="flex-start"
            >
              {/* Title and article number  */}
              <FlexBox mb={1}>
                <ThemeTypography variant="TITLE_MEDIUM" data-cy="component-title">
                  {component.title}
                </ThemeTypography>
                {component.articleNumber && (
                  <FlexBox ml={2}>
                    <ThemeTypography variant="TITLE_EXTRA_SMALL" color="GRAY_40">
                      |
                    </ThemeTypography>
                    <Box mr={1} />
                    <ThemeTypography
                      variant="TITLE_EXTRA_SMALL"
                      autoOverflow
                      maxWidth={200}
                      color="GRAY_40"
                    >
                      {component.articleNumber}
                    </ThemeTypography>
                  </FlexBox>
                )}
                {component.partner && (
                  <Box display="flex" alignItems="center" ml={2}>
                    <Image
                      image={component.partner?.logo}
                      backupImageUrl={PartnerPlaceholderImage}
                      width={42}
                      height={42}
                    />
                    <Box ml={1}>
                      <ThemeTypography variant="ITEM_SUBTITLE">
                        {component.partner?.title}
                      </ThemeTypography>
                    </Box>
                  </Box>
                )}
              </FlexBox>

              {/* Raw materials  */}
              <FlexBox>
                <Icon name="raw-material" mr={0.5} color="gray-80" />
                <ThemeTypography
                  variant="BODY_MEDIUM_BOLD"
                  color="GRAY_80"
                  maxWidth={300}
                  autoOverflow
                >
                  {component.rawMaterials?.reduce(
                    (prev, rawMaterial) =>
                      prev ? `${prev}, ${rawMaterial.title}` : rawMaterial.title,
                    ''
                  )}
                </ThemeTypography>
              </FlexBox>

              {/* Action buttons  */}
              <FlexBox mt={2.5}>
                <ThemeButton
                  startIcon={<Edit size={14} />}
                  size="large"
                  color="BLUE_ICE"
                  loading={false}
                  onClick={() => handleEditComponent(component)}
                  data-cy="edit-component-btn"
                >
                  Edit component
                </ThemeButton>
                {isPartOfChain && (
                  <Box ml={1}>
                    <ThemeButton
                      startIcon={<Icon name="component-chain" />}
                      data-cy="map-component-chain-btn"
                      size="large"
                      color="YELLOW"
                      loading={false}
                      onClick={handleCreateComponentChain}
                    >
                      Map new component chain
                    </ThemeButton>
                  </Box>
                )}
              </FlexBox>
            </Box>
          </Box>
        </OverviewHeader>
      </Grid>

      <ConditionalRender condition={!isPartOfChain}>
        {({ True, False }) => (
          <>
            <True>
              <Box mt={2} />
              <EmptyStateCard
                title="Map your component chains to apply and re-use in your product supply chains"
                onCreateItemClick={handleCreateComponentChain}
                variant={ItemTypes.COMPONENT_CHAIN}
                buttonIcon={<Icon name="component-chain" />}
                buttonText="Add component chain"
                background={BackgroundTypes.WORLD}
                size={AvailableSizes.MEDIUM}
                renderIllustration={
                  <CardIllustration
                    image={IllustrationTypes.LOCATIONS}
                    width={300}
                    right={25}
                    top={0}
                  />
                }
                data-cy="component-chains-getting-started-card"
              />
            </True>
            <False>
              <Box mt={4}>
                <StyledSubTitle variant="TITLE_EXTRA_SMALL">
                  Chains that include this component
                </StyledSubTitle>
              </Box>
              {component.componentChains?.length && component.supplyChains?.length ? (
                <>
                  <Box mt={1.5} mb={1} display="flex" justifyContent="space-between">
                    <Tabs
                      tabs={COMPONENT_OVERVIEW_TABS}
                      selectedTab={currentTab}
                      onChange={handleChangeTab}
                    />
                  </Box>
                  {currentTab === IComponentOverviewTabs.COMPONENT_CHAINS ? (
                    <ComponentChainTable
                      columnKeys={[
                        COMPONENT_CHAIN_COLUMN_IDS.TITLE,
                        COMPONENT_CHAIN_COLUMN_IDS.ACTIONS,
                      ]}
                      componentChains={component.componentChains}
                      actions={componentTableActions}
                      onRowClick={handleRowClick}
                    />
                  ) : (
                    <ProductSupplyChainTable
                      productSupplyChains={component.supplyChains}
                      actions={supplyChainTableActions}
                    />
                  )}
                </>
              ) : (
                <>
                  {!!component?.componentChains?.length && (
                    <ComponentChainTable
                      columnKeys={[
                        COMPONENT_CHAIN_COLUMN_IDS.TITLE,
                        COMPONENT_CHAIN_COLUMN_IDS.ACTIONS,
                      ]}
                      componentChains={component.componentChains}
                      actions={componentTableActions}
                      onRowClick={handleRowClick}
                    />
                  )}
                  {!!component?.supplyChains?.length && (
                    <ProductSupplyChainTable
                      productSupplyChains={component.supplyChains}
                      actions={supplyChainTableActions}
                    />
                  )}
                </>
              )}
            </False>
          </>
        )}
      </ConditionalRender>
    </PageContainer>
  );
};

export default ComponentItemOverview;
