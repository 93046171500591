import { Box } from '@mui/material';
import { PageContainer, PageTitle } from 'components/Structure';
import { usePageTitle } from 'components/hooks';
import { InfoTooltip } from 'designSystem';
import Tabs, { ITab } from 'designSystem/Primitives/Tabs/Tabs';
import React, { FC } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AvailableSizes } from 'types/enums';

type TabType = 'internal' | 'external' | 'unassigned';

const SITES_TABS: ITab[] = [
  {
    key: 'internal',
    label: 'Internal',
  },
  {
    key: 'external',
    label: 'External',
  },
  {
    key: 'unassigned',
    label: 'Unassigned',
  },
];

const SitesLayout: FC = () => {
  usePageTitle('Sites');
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const selectedTab: TabType = pathname.split('/')[2] as TabType;

  const handleChangeTab = (tab: string) => {
    navigate(`/sites/${tab}`);
  };

  return (
    <PageContainer>
      <PageTitle
        title="Sites"
        TitleAdornment={
          <Box ml={1}>
            <InfoTooltip
              size={AvailableSizes.LARGE}
              text="Sites can be farms, processing plants, storage facilities, factories, etc. Manage the sites as where supply chain activities are happening and who owns them."
            />
          </Box>
        }
      />

      <Box mt={3} mb={1}>
        <Tabs tabs={SITES_TABS} selectedTab={selectedTab} onChange={handleChangeTab} />
      </Box>

      <Outlet />
    </PageContainer>
  );
};

export default SitesLayout;
