import { ImageVariant } from './commonTypes';
import { Partner } from './partner.types';
import { Coordinates } from './types';

export enum SiteOwnershipType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  UNASSIGNED = 'UNASSIGNED',
}

export enum SiteType {
  FARM = 'FARM',
  MINE = 'MINE',
  FOREST = 'FOREST',
  COLLECTION_SITE = 'COLLECTION_SITE',
  ORIGIN_SITE = 'ORIGIN_SITE',
  PLANT = 'PLANT',
  PROCESSING_SITE = 'PROCESSING_SITE',
  FACTORY = 'FACTORY',
  MANUFACTURING_SITE = 'MANUFACTURING_SITE',
  WAREHOUSE = 'WAREHOUSE',
  HANDLING_SITE = 'HANDLING_SITE',
  GROCERY_STORE = 'GROCERY_STORE',
  DISTRIBUTION_SITE = 'DISTRIBUTION_SITE',
  GENERIC_SITE = 'GENERIC_SITE',
  CLUSTER = 'CLUSTER',
}

export enum LandOwnershipType {
  PRIVATE_OWNERSHIP = 'PRIVATE_OWNERSHIP',
  PUBLIC_OWNERSHIP = 'PUBLIC_OWNERSHIP',
  COMMUNAL_OWNERSHIP = 'COMMUNAL_OWNERSHIP',
  STATE_OWNERSHIP = 'STATE_OWNERSHIP',
  TENANCY = 'TENANCY',
  CONCESSION = 'CONCESSION',
  EASEMENT = 'EASEMENT',
  COLLECTIVE_FARMS = 'COLLECTIVE_FARMS',
  TRUSTS = 'TRUSTS',
  COMMON_PROPERTY_REGIMES = 'COMMON_PROPERTY_REGIMES',
  ADVERSE_POSSESSION = 'ADVERSE_POSSESSION',
  LAND_COOPERATIVES = 'LAND_COOPERATIVES',
  USUFRUCT_RIGHTS = 'USUFRUCT_RIGHTS',
  OTHER = 'OTHER',
}

export interface IBaseSite {
  id: string;
  title: string;
  partner?: Partner;
  locationName: string;
  locationCoordinates: Coordinates;
  image?: ImageVariant | null;
  createdAt: string;
  externalId?: string;
  description?: string;
}

export type IGenericSite = IBaseSite;

export type IMineSite = IBaseSite;

export type IForestSite = IBaseSite;

export type ICollectionSite = IBaseSite;

export type IOriginSite = IBaseSite;

export type IPlantSite = IBaseSite;

export type IProcessingSite = IBaseSite;

export type IFactorySite = IBaseSite;

export type IManufacturingSite = IBaseSite;

export type IWarehouseSite = IBaseSite;

export type IHandlingSite = IBaseSite;

export type IGroceryStoreSite = IBaseSite;

export type IDistributionSite = IBaseSite;

export interface IFarmSite extends IBaseSite {
  rawMaterialId?: string;
  size?: number;
  numberOfFarmers?: number;
  additionalRawMaterialIds?: string[];
  landOwnership?: LandOwnershipType;
}

export type ISiteTypeWithoutCluster =
  | ({ siteType: SiteType.FARM } & IFarmSite)
  | ({ siteType: SiteType.GENERIC_SITE } & IGenericSite)
  | ({ siteType: SiteType.CLUSTER } & IClusterSite)
  | ({ siteType: SiteType.MINE } & IMineSite)
  | ({ siteType: SiteType.FOREST } & IForestSite)
  | ({ siteType: SiteType.COLLECTION_SITE } & ICollectionSite)
  | ({ siteType: SiteType.ORIGIN_SITE } & IOriginSite)
  | ({ siteType: SiteType.PLANT } & IPlantSite)
  | ({ siteType: SiteType.PROCESSING_SITE } & IProcessingSite)
  | ({ siteType: SiteType.FACTORY } & IFactorySite)
  | ({ siteType: SiteType.MANUFACTURING_SITE } & IManufacturingSite)
  | ({ siteType: SiteType.WAREHOUSE } & IWarehouseSite)
  | ({ siteType: SiteType.HANDLING_SITE } & IHandlingSite)
  | ({ siteType: SiteType.GROCERY_STORE } & IGroceryStoreSite)
  | ({ siteType: SiteType.DISTRIBUTION_SITE } & IDistributionSite);

export interface IClusterSite<SiteType = ISiteTypeWithoutCluster> extends IBaseSite {
  sites?: {
    edges: {
      node: SiteType;
    }[];
  };
  count: number;
  summary: { workerAmount: number; additionalRawMaterialTitles: string[] };
}

export type ISite = ISiteTypeWithoutCluster | ({ siteType: SiteType.CLUSTER } & IClusterSite);
